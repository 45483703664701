/**
 * @desc 点阵笔
 */
// const
import { TYPE_STUDENT, TYPE_TEACHER } from './constance';

// api
import {
  getPenPageJSON,
  deletePen,
  getPenModelList,
  getErrorPenPageJSON,
  getPenVersionList,
} from '@/api/equipment-manage';
import { getGradeList, getClassList } from '@/api/basic';

// component
import { Table, TableColumn, Input, Button, Select, Option, Checkbox } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import Dialog from './components/dialog.vue';
import EButton from '@components/button/index.vue';
import ETable from '@components/table/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';

export default {
  name: 'pen',
  components: {
    ETable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    basicHeader,
    searchInput,
    qlPagination,
    Dialog,
    EButton,
  },

  data() {
    return {
      TYPE_STUDENT,
      TYPE_TEACHER,

      gradeId: '-1', // 年级ID
      classId: '-1', // 班级ID

      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        type: '0',
        gradeId: '',
      },

      multipleSelection: [],

      dialogVisible: false,
      dialogIsEdit: false,
      dialogDefaultvalue: undefined,

      subTitle: '',

      penModelList: [],
      penVersionList: [],
      penTypeList: [
        { text: '学生点阵笔', value: 1 },
        { text: '教师点阵笔', value: 3 },
      ],
      gradeList: [],
      classList: [],

      selectedRows: [],
      filterOptions: {
        onlyError: false, // 仅显示异常数据
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,

        pageChange: this.pageChange,
      },

      noneGradeTeacherNames: '', // 没有任教的教师姓名列表
      multiGradeTeacherNames: '', // 多个年级任教的教师姓名列表
      excTeacherIds: [], // 异常的教师ID列表
    };
  },

  methods: {
    pageChange() {
      this.getTableData();
    },

    async handleSelectAll() {
      let params = {
        pageIndex: 1,
        pageSize: this.pagination.total,
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,

        ...this.filterOptions,
        deviceTypes: this.filterOptions.deviceTypes?.split(','),
        firmwareVersions: this.filterOptions.firmwareVersions?.split(','),
        usertypes: this.filterOptions.usertypes?.split(','),
        gradeIds: this.filterOptions.gradeIds?.split(','),
        classIds: this.filterOptions.classIds?.split(','),
      };

      if (this.filterOptions.onlyError) {
        params['userIds'] = this.excTeacherIds;
      } else {
        delete params['userIds'];
      }

      getPenPageJSON(params).then((res) => {
        if (res.status === 0) {
          this.selectedRows = res.result.data;
        }
      });
    },

    getTableData() {
      let params = {
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pageSize,
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,

        ...this.filterOptions,
        deviceTypes: this.filterOptions.deviceTypes?.split(','),
        firmwareVersions: this.filterOptions.firmwareVersions?.split(','),
        usertypes: this.filterOptions.usertypes?.split(','),
        gradeIds: this.filterOptions.gradeIds?.split(','),
        classIds: this.filterOptions.classIds?.split(','),
      };

      if (this.filterOptions.onlyError) {
        params['userIds'] = this.excTeacherIds;
      } else {
        delete params['userIds'];
      }

      getPenPageJSON(params).then((res) => {
        if (res.status === 0) {
          this.table.list = res.result.data;
          this.pagination.total = +res.result.recordCount;
        }
      });
    },

    getErrorData() {
      getErrorPenPageJSON({
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,
      }).then((res) => {
        if (res.status === 0) {
          const { noneGradeTeacherNames, multiGradeTeacherNames, excTeacherIds } = res.result;
          this.noneGradeTeacherNames = noneGradeTeacherNames.join('，');
          this.multiGradeTeacherNames = multiGradeTeacherNames.join('，');
          this.excTeacherIds = excTeacherIds;
        }
      });
    },

    getPenModelList() {
      getPenModelList({
        upgradePlanId: this.$route.query.upgradePlanId,
      }).then((res) => {
        if (res.status === 0) {
          this.penModelList = res.result.map((item) => ({ text: item.deviceTypeName, value: item.deviceType }));
        }
      });
    },

    getPenVersionList() {
      getPenVersionList().then((res) => {
        if (res.status === 0) {
          this.penVersionList = res.result.map((item) => ({ text: item, value: item }));
        }
      });
    },

    getGradeList() {
      getGradeList({
        status: 0,
        upgradePlanId: this.$route.query.upgradePlanId,
        schoolYearId: this.$route.query.schoolYearId,
      }).then((res) => {
        if (res.status === 0) {
          this.gradeList = res.result.map((item) => ({ text: item.gradeName, value: item.gradeId }));
        }
      });
    },

    getClassList() {
      getClassList({
        status: 0,
        pageSize: 99999,
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,
      }).then((res) => {
        if (res.status === 0) {
          this.classList = res.result.data.map((item) => ({
            text: `${item.gradeName}${item.className}`,
            value: item.classId,
          }));
        }
      });
    },

    filterChangeHandler(filterObj) {
      // 可选
      this.filterOptions = {
        ...this.filterOptions,
        [Object.keys(filterObj)[0]]:
          Object.values(filterObj)[0].length > 0 ? Object.values(filterObj)[0].join(',') : undefined,
      };
    },

    // handle 新增点阵笔
    handleAdd() {
      this.dialogIsEdit = false;
      this.dialogVisible = true;
      this.dialogDefaultvalue = undefined;
    },
    // handle 编辑
    handleEdit(val) {
      this.dialogIsEdit = true;
      this.dialogVisible = true;
      this.dialogDefaultvalue = val;
    },

    // handle 批量删除
    handleBatchDelete() {
      this.$msgbox
        .confirm(`确定批量删除这${this.selectedRows.length}支点阵笔吗？`, '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deletePen({
            pubDeviceDzbUserIds: this.selectedRows.map((item) => item.pubDeviceDzbUserId),
            schoolYearId: this.$route.query.schoolYearId,
            upgradePlanId: this.$route.query.upgradePlanId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '点阵笔批量删除成功!',
            });
            this.selectedRows = [];
            this.pagination.page = 1;
            this.getTableData();
            this.getErrorData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消',
          });
        });
    },

    // handle 删除
    handleDelete(val) {
      this.$msgbox
        .confirm('确定删除此点阵笔吗？', '提示', {
          customClass: 'ql-message-box_notitle',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deletePen({
            pubDeviceDzbUserIds: [val.pubDeviceDzbUserId],
            schoolYearId: this.$route.query.schoolYearId,
            upgradePlanId: this.$route.query.upgradePlanId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '点阵笔删除成功!',
            });
            this.getTableData();
            this.getErrorData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消',
          });
        });
    },

    // 设置默认选中的行
    setDefaultSelectRow() {
      setTimeout(() => {
        this.$refs.table.clearSelection();
      });
      setTimeout(() => {
        // 设置当前页原有选中
        const list = this.table.list.filter((x) => x.checked);
        list.forEach((x) => {
          this.$refs.table.toggleRowSelection(x, true);
        });
      });
    },

    // 批量录入
    handleImport() {
      this.$batchEntry({
        type: 'pen',
        UNIT: '支',
        downloadMethod: 'GET',
        data: {
          schoolYearId: this.$route.query.schoolYearId,
          upgradePlanId: this.$route.query.upgradePlanId,
        },
        callback: () => {
          this.pagination.page = 1;
          this.getTableData();
          this.getErrorData();
        },
      });
    },

    // 批量导出
    handleExport() {
      if (this.pagination.total === 0) {
        this.$message.info('暂无数据!');
        return;
      }

      this.$batchExport({
        type: 'pen',
        downloadMethod: 'POST',
        data: {
          pubDeviceDzbUserIds: this.selectedRows.map((item) => item.pubDeviceDzbUserId),
          schoolYearId: this.$route.query.schoolYearId,
          upgradePlanId: this.$route.query.upgradePlanId,
        },
      });
    },
  },

  mounted() {
    this.getTableData();
    this.getPenModelList();
    this.getPenVersionList();
    this.getGradeList();
    this.getClassList();
    this.getErrorData();
  },

  computed: {
    errorTextList() {
      return [
        this.noneGradeTeacherNames.split(',').filter(Boolean),
        this.multiGradeTeacherNames.split(',').filter(Boolean),
      ];
    },

    columns() {
      return [
        {
          label: '点阵笔编号',
          prop: 'dzbNo',
          width: 160,
        },
        {
          label: '点阵笔型号',
          prop: 'deviceType',
          columnKey: 'deviceTypes',
          filters: this.penModelList,
        },
        {
          label: '固件版本',
          prop: 'firmwareVersion',
          columnKey: 'firmwareVersions',
          filters: [],
        },
        {
          label: '点阵笔类型',
          prop: 'usertype',
          columnKey: 'usertypes',
          filters: this.penTypeList,
          formatter: (row, col) => col.filters.find((item) => +item.value === row.userType)?.text || '-',
        },
        {
          label: '所属学生学号/教师教职工号',
          prop: 'idNumber',
          width: 210,
        },
        {
          label: '所属学生/教师姓名',
          prop: 'userName',
        },
        {
          label: '所属年级',
          prop: 'gradeName',
          columnKey: 'gradeIds',
          filters: this.gradeList,
        },
        {
          label: '所属班级',
          prop: 'className',
          columnKey: 'classIds',
          filters: this.classList,
          formatter: (row) => row.className || '-',
        },
        {
          label: '操作',
          props: () => ({
            btnList: [
              {
                text: '编辑',
                disabled: () => this.notAllowOperate,
                handle: this.handleEdit,
              },
              {
                text: '删除',
                disabled: () => this.notAllowOperate,
                handle: this.handleDelete,
              },
            ],
          }),
          component: OperationBtns,
        },
      ];
    },
  },

  watch: {
    filterOptions: {
      handler() {
        this.pagination.page = 1;
        this.getTableData();
      },
      deep: true,
    },
  },
};
