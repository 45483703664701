<template>
  <el-select
    v-model="userId"
    v-loading="isLoading"
    :placeholder="`请输入${type === '1' ? '学生' : '教师'}姓名`"
    style="width: 100%"
    :title="userName"
    @change="selectUser"
    filterable
    :filter-method="dataFilter"
  >
    <el-option v-for="item in userList" :key="item.userId" :label="optionLabel(item)" :value="item.userId" />
  </el-select>
</template>

<script>
// api
import { getUserByNoOrName } from '@/api/equipment-manage';
// const
import { TYPE_STUDENT, TYPE_TEACHER } from '../constance';
// components
import { Input, Select, Option } from 'element-ui';
// debounce
import debounce from 'throttle-debounce/debounce';

export default {
  name: 'studentSelect',

  components: {
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    // 默认选中（包含id, name)
    defaultValue: {
      type: Object,
      require: false,
      // default: {
      //   id: '',
      //   name: '',
      // },
    },

    type: {
      type: String,
      require: true,
      default: TYPE_STUDENT,
    },
  },

  data() {
    return {
      isLoading: false,

      searchKey: '',

      userId: '',
      userName: '',
      userList: [],

      manualSelected: false, // 手动选中
    };
  },

  computed: {
    placeholder() {
      if (this.type === TYPE_STUDENT) {
        return '请输入学号/姓名关键字进行检索';
      }
      if (this.type === TYPE_TEACHER) {
        return '请输入教职工号/姓名关键字进行检索';
      }
      return '请输入学号/姓名关键字进行检索';
    },
  },

  methods: {
    // handle 选择学校
    selectUser(id) {
      let user = this.userList.find((item) => item.userId == id);
      this.userName = user.userName;
      this.$emit('select', id);
      this.manualSelected = true;
    },

    dataFilter(val) {
      getUserByNoOrName({
        searchKey: val.trim(),
        identity: this.type,
        schoolYearId: this.$route.query.schoolYearId,
        upgradePlanId: this.$route.query.upgradePlanId,
      })
        .then((res) => {
          this.userList = res.result.length ? res.result : [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // api 获取列表
    getList() {
      this.isLoading = true;

      return new Promise((resolve) => {
        getUserByNoOrName({
          searchKey: this.searchKey.trim(),
          identity: this.type,
          schoolYearId: this.$route.query.schoolYearId,
          upgradePlanId: this.$route.query.upgradePlanId,
        })
          .then((res) => {
            this.userList = res.result.length ? res.result : [];
            resolve(res.result);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },

    optionLabel(data) {
      return data.idNumber ? `${data.name}(${data.idNumber})` : data.name;
    },
  },

  created() {
    this.debouncedOnSearch = debounce(800, () => {
      this.getList();
    });
  },

  watch: {
    defaultValue: {
      handler: async function (val) {
        // 编辑
        if (!this.manualSelected && val?.id) {
          // 默认自动选中
          this.searchKey = val.name;
          this.userId = val.id;
          this.userName = val.name;
          await this.getList();

          this.$emit('select', val.id, true);
        }
        // reset
        if (val === undefined) {
          Object.assign(this.$data, this.$options.data());
        }
      },
      immediate: true,
    },

    type() {
      this.userList = [];
    },
  },
};
</script>
